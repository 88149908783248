.full-width-gallery-wrapper {
    width: 100%;
    display: flex;
    zoom: 1;
    flex-direction: column;
}

.full-width-gallery-photo {
    width: 100%;
    overflow: hidden;
    background-color: #000;
    margin: 0;
}

.full-width-gallery-photo img {
    opacity: 0.7;
    width: 100%;
    height: auto;
    transform: scale(1.15);
    transition: transform 0.5s, opacity 0.5s;
}

.full-width-gallery-photo img:hover {
    transform: scale(1.05);
    opacity: 1;
}

@media only screen and (min-width: 550px) {
    .full-width-gallery-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
    }
    .full-width-gallery-photo {
        width: 50%;
    }
}

@media only screen and (min-width: 921px) {
    .full-width-gallery-photo {
        width: 25%;
    }

    .full-width-gallery-wrapper {
    }
}