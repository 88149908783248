@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

.sponsors-link,
.sponsors-link:hover,
.sponsors-link:visited {
  width: 100%;
  height: 100%;
  display: contents;
  color: black;
}

.sponsors-wrapper {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.sponsors-large-heading,
.sponsors-medium-heading,
.sponsors-small-heading {
  font-family: 'Roboto', sans-serif;
}

.sponsors-large-heading {
  font-size: 3rem;
}

.sponsors-medium-heading {
  font-size: 2.5rem;
}

.sponsors-small-heading {
  font-size: 2rem;
}

.sponsors-list {
  text-align: center;
}

.sponsors-item {
  font-size: 1.5rem;
  list-style: none;
  padding: 10px 0;
  margin: 20px 0;
}

.sponsors-item img {
  max-width: 300px;
  max-height: 350px;
}

.l-text {
  font-size: 2.5rem;
  text-align: center;
}


@media only screen and (min-width: 950px) {
  .sponsors-wrapper section {
    margin: 20px auto !important;
    padding: 0 !important;
  }

  .sponsors-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin: 0;
  }

  .sponsors-item {
    width: 25%;
    box-sizing: border-box;
    /* margin: 25 10px; */
    margin: 0;
    padding: 0;
  }

  .sponsors-item img {
    box-sizing: border-box;
    max-width: 100%;
    max-height: 200px;
    padding: 0px 20px;
  }
  
  .sponsors-large-heading {
    font-size: 3rem;
    margin: 0;
    padding: 0;
  }

  .sponsors-medium-heading {
    font-size: 2rem;
  }

  .sponsors-small-heading {
    font-size: 1.5rem;
  }

  .l-text {
    font-size: 1.5rem;
  }

  .specialty-container {
    display: flex; 
  }
  .specialty-container .sponsors-item {
    width: 40%;
  }

}