.icon--mobile {
    position: fixed;
    z-index: 1000;
    font-size: 30px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    animation: pulse 8s infinite ease;
}

.fa-times {
    color: white;
}

.fa-bars {
    color: #777;
}

.navbar--mobile {
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    color: white;
    transform: translate(0, -100%);
    transition: transform 0.2s ease;
}

.show-nav--mobile {
    transform: translate(0);
}


/* STYLES */ 

.navbar--mobile ul {
    width: 100%;
    height: 100%;
    list-style: none;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.navbar--mobile ul li {
    width: 100%;
    text-align: center;
}

.navbar--mobile ul li a {
    display: block;
    color: white;
    border-bottom: 2px solid black;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 1em;
}

.navbar--mobile ul li a:hover, .navbar--mobile ul li a:focus {
    text-decoration: underline;
}