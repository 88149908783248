.hero-text-box {
    min-height: 100vh;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.hero-text-box div {
    position: absolute;
    top: 275px;
    left: 5%;
}

.hero-text-box div h1 {
    font-size: 2rem;
}

.hero-text-box div p {
    padding: 7.5px;
    font-size: 1.5rem;
}

.hero-text-box a {
    scroll-behavior: smooth;
}

@media only screen and (min-width: 921px) {
    .hero-text-box div h1 {
        font-size: 3.5rem;
    }

    .hero-text-box div p {
        font-size: 3rem;
    }
}