@import url(./Buttons.css);
@import url(./Hero-Box.css);

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

html, body, #root, .root {
    height: 100%;
}

.root {
    display: flex;
    flex-direction: column;
}

header {
    z-index: 1000;
}

.content {
    flex: 1 0 auto;
    filter: brightness(120%);
}

.top-section {
    padding-top: 75px;
}

section {
    padding: 15px 0;
    width: 80%;
    margin: 5px auto;
}

section h1 {
    text-align: center;
    padding: 10px;
    font-size: 2.5rem;
    word-spacing: 2px;
}

section h1:after {
    display: block;
    height: 2px;
    background-color: #48B945;
    content: " ";
    width: 100px;
    margin: 15px auto;
}

section h2 {
    text-align: center;
    font-size: 1.75rem;
    margin-bottom: 15px;
}

section p {
    padding: 5px;
    margin: 5px 0;
}

.content .content--nav {
    margin-top: 5px;
}

.content--nav {
    text-decoration: none;
}

.content--nav li {
    list-style: none;
    padding: 2.5px;
    text-align: center;
}


/**** FOOTER ****/

footer {
    flex-shrink: 0;
}