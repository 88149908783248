footer {
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  
  /*** LOGO ***/
  
  .footer--logo {
    font-size: 1em;
    text-align: left;
    padding: 25px 0;
    margin: 5px 15px;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: right;
    align-content: center;
    flex-direction: column;
  }

  .footer--logo img {
    max-width: 200px;
  }
  
  /* Tablet */
  @media(min-width: 767px) {
    .footer--logo {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .footer--logo--col1 {
      text-align: left;
    }
  }
  
  .footer--logo--col1 {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 0 10px 5px 0;
  }
  
  .footer--logo--links{
    color: white;
  }
  
  .footer--logo--col1 p {
    font-size: 1.1em;
    padding: 4px;
  }
  
  .footer--logo--col1 p a{
    color: white;
    text-decoration: none;
  }
  
  .footer--logo--col1 p a:hover{
    text-decoration: underline;
  }

  /*** CONTACT ***/

  .footer--contact h3 {
    font-size: 1.5rem;
  }
  
  /*** COLUMNS ***/
  
  .footer--columns {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
  
  /* Tablet */
  @media(min-width: 767px) {
    .footer--columns {
      grid-template-columns: repeat(2, 50%);
      text-align: left;
    }
  }
  
  /* Desktop */
  @media(min-width: 1025px) {
    .footer--columns {
      grid-template-columns: repeat(3, 33%);
    }
  }
  
  .footer--column {
    margin: 20px 50px;
  }
  
  .footer--column h3 {
    text-decoration: underline;
  }
  
  .footer--column ul {
    list-style-type: none;
  }
  
  .footer--column--link {
    font-size: .85em;
    text-decoration: none;
    color: white;
  }
  
  .footer--column--link:hover {
    text-decoration: underline;
  }
  
  
  /*** LEGAL ***/
  
  .footer--legal {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: left;
    font-size: .8em;
    font-style: italic;
    margin: 5px 15px;
    padding: 30px 0;
    border-top: 1px solid white;
  }