.pilot-card {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: 10px;
}

@media only screen and (min-width: 500px) {
    .pilot-card {
        width: 50%;
    }
}

@media only screen and (min-width: 921px) {
    .pilot-card {
        width: 25%;
    }
}

.pilot-card--image {
    max-height: 200px;
    border-radius: 10%;
    margin: 10px 0;
}

.pilot-card--text {
    display: flex;
    flex-direction: column;
}

.pilot-card--balloon-name {
    text-decoration: underline;
    text-align: center;
    font-size: 1.25rem;
}

.pilot-card--pilot-name,
.pilot-card--sponsor,
.pilot-card--location {
    text-align: center;
    font-size: 1rem;
}

.italic {
    font-style: italic;
}