.content--section {
    margin: 30px 0;
    padding: 25px;
    color: white;
  }

.event-rules div img {
  max-width: 100%;
}
  
.top-section h2,
.top-section h3 {
  text-align: center;
}
.top-section h2 {
  font-size: 1.75rem;
}

.top-section h3 {
  font-size: 1.25rem;
  margin: 2px auto;
}

.schedule-wrapper {
  padding: 5px;
  width: 100%;
}

.day-wrapper {
  margin: 0 auto;
  padding: 10px 0;
  text-align: left;
}

.event-day {
  font-size: 2rem;
  font-weight: bolder;
  text-decoration: underline;
}

.event-info-wrapper {
  list-style: none;
}

.event-info {
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1.1rem;
  margin: 15px 0;
  padding: 0 5px;
}

.event-info:nth-child(odd) {
  background-color: rgba(119, 119, 119, 0.15);
}

.event-time {
  font-weight: 900;
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
}

.event-info-text {
  text-align: right;
  margin: 0;
  padding: 0;
}

.schedule-disclaimer {
  width: 80%;
  margin: 0 auto;
  padding: 25px;
  font-style: italic;
}

.event-rules {
  margin: 0 auto;
  text-align: center;
}

@media only screen and (min-width: 950px) {
  .event-time {
    width: 25%;
    text-align: left;
    margin: 0;
    padding: 0;
  }
  .event-info-text {
    text-align: left;
  }
}