.content--section {
    margin: 30px 0;
    padding: 25px;
    color: white;
  }

.vendorInfo {
  text-align: center;
  font-size: 1.25rem;
  padding: 5px;
}

.vendorButtons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vendorButtons div {
  margin-top: 5px;
}