.content--section {
  margin: 30px 0;
  padding: 25px;
  color: white;
}

.vendorIcon {
color: green;
font-size: 2rem;
padding: 5px;
}

.sponsor-levels-title {
font-size: 2.5rem;
text-align: center;
padding: 15px;
}

.sponsor-levels-title::after {
display: block;
height: 2px;
background-color: rgb(72, 185, 69);
content: " ";
width: 100px;
margin: 30px auto 0px;
}

.sponsor-cards-wrapper {
display: flex;
flex-wrap: wrap;
}

.sponsor-card {
width: 100%;
text-align: center;
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
margin: 10px 0;
}

.sponsor-card:hover {
box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.sponsor-title {
font-size: 2.5rem;
font-weight: bold;
padding: 10px;
margin: 0;
border-bottom: 1px solid rgba(0,0,0,.2);
}

.sponsor-price {
font-size: 1.75rem;
padding: 7.5px;
color: green;
border-bottom: 1px solid rgba(0,0,0,.2);
margin: 0;
}

.sponsor-list {
text-align: left;
list-style: none;
padding: 10px;
}

.sponsor-list-item {
padding: 2.5px; 
margin: 2px 0;
font-size: 1.1rem;
}

.fa-check {
margin-right: 5px;
}

@media only screen and (min-width: 950px) {

.sponsor-cards-wrapper {
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0;
  margin: 0
}

.sponsor-card {
  box-sizing: border-box;
  width: 45%;
  margin: 15px;
}
}