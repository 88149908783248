.content--section {
  margin: 30px 0;
  padding: 25px;
  color: white;
}

.special-shape-balloon-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.special-shape-balloon-wrapper {
  width: 100%;
  text-align: center;
  margin: 30px auto;
  text-decoration: underline;
}

.special-shape-balloon-wrapper h2 {
  font-size: 1.9em;
}

.special-shape-balloon {
  max-width: 300px;
  transition: transform .2s; /* Animation */
  padding: 10px;
  box-shadow: 8px 8px 10px #aaa;
  border: 5px double transparent;
  border-image: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%);
  border-image-slice: 1;
}

.special-shape-balloon:hover {
  transform: scale(105%);
}

.special-shape--sponsor {
  text-align: center;
  font-size: 1.4rem;
  padding: 15px;
}

.italic {
  font-style: italic;
}

@media only screen and (min-width: 950px) {
  .special-shape-balloon-wrapper {
    width: 50%;
  }
}