.btn:link,
.btn:visited,
input[type=submit] {
    font-size: 1.1rem;
    display: inline-block;
    padding: 10px 30px;
    font-weight: 300;
    text-decoration: none;
    border-radius: 100px;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
}

.btn-full:link,
.btn-full:visited,
input[type=submit] {
    background-color: #48B945;
    border: 1px solid #48B945;
    color: #fff;
    margin-right: 15px;
}

.btn-ghost:link,
.btn-ghost:visited {
    color: #48B945;
    border: 1px solid #48B945;
}

.btn:hover,
.btn:active,
input[type=submit]:hover,
input[type=submit]:active {
    background-color: #3ea03c;
}

.btn-full:hover,
.btn-full:active,
input[type=submit]:hover,
input[type=submit]:active {
    border: 1px solid #3ea03c;
}

.btn-ghost:hover,
.btn-ghost:active {
    border: 1px solid #3ea03c;
    color: #fff;
}

.learn-more-btn {
    background-color: #3ea03c;
    border: 1px solid #3ea03c;
    color: #fff;
    font-size: 1.1rem;
    display: inline-block;
    padding: 10px 30px;
    font-weight: 300;
    text-decoration: none;
    border-radius: 100px;
    transition: background-color 0.2s, border 0.2s, color 0.2s;

}