.pilot-registration-wrapper {
    font-size: 1.1rem;
}

.pilot-registration-wrapper form section{
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 5px auto;
}

.pilot-registration-wrapper form input,
.pilot-registration-wrapper form select,
.pilot-registration-wrapper form textarea
 {
    margin: 5px;
    margin-bottom: 15px;
    padding: 5px;
}

.pilot-registration-wrapper form button {
    font-size: 1.5rem;
    font-weight: bold;
}

.pilot-registration-wrapper form button {
    margin-top: 10px;
    padding: 15px;
}

.font--bold {
    font-weight: bold;
}

.hotel-info p {
    text-align: center;
    margin: 2px;
    padding: 0;
}

.sending-spinner {
    display: inline-block;
    margin-left: 10px;
}

.warning-red {
    color: red;
}

.select-placeholder {
    color: #999;
    font-style: italic;
}