.not-found__wrapper {
    min-height: 50vh;
    margin: 150px auto;
    width: 80%;
}

.not-found__header {
    text-align: center;
}

.not-found__image {
    display: block;
    margin: 0 auto;
}

.not-found__text {
    text-align: center;
}

@media only screen and (max-width: 921px) {
    .not-found__wrapper {
        box-sizing: border-box;
        width: 100%;
    }
    .not-found__image,
    .not-found__text,
    .not-found__header {
        max-width: 80%;
        margin: 0 auto;
    }
    .not-found__text {
        text-align: center;
    }
}