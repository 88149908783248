.navbar--desktop {
  position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(0,0,0, .7);
  }
  
  .nav-logo--desktop {
    padding-left: 10px;
    color: white;
  }
  
  .navbar--desktop ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style-type: none;
    margin: 0;
  }
  
  .navbar--desktop ul li {
    /* padding: 15px 10px; */
  }
  
  .navbar--desktop ul li a{
    font-size: 1.2rem;
    padding: 15px 15px;
    color: white;
    display: block;
    height: 100%;
    text-decoration: none;
  }

  .menu-link--desktop--logo a {
    padding: 2.5px;
  }

  .menu-link--desktop--logo img {
    max-height: 80px;
  }

  .menu-link--desktop:hover {
    text-decoration: underline;
  }