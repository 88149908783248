/***** CONTACT INFO ****/
.contact-info {
  margin-bottom: 20px;
}

.contact-info-list {
  list-style: none;
  text-align: center;
}

.contact-info-list li {
  padding: 2px;
}

/***** EVENT INFO ****/
.event-section {
  text-align: center;
  margin: 50px auto;
}

.round-image {
    border-radius: 2.5%;
    max-width: 80%;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: transform 0.5s, opacity 0.5s;
}

.round-image:hover {
    transform: scale(1.02);
    opacity: 1;
}

.section-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-content-left {
  box-sizing: border-box;
  width: 100%;
}

.section-conten-right {
  box-sizing: border-box;
  width: 100%;
}

.food-list {
  list-style: none;  
}

.event-rules {
  margin: 0 auto;
  text-align: center;
}

.event-rules div img {
  max-width: 100%;
}

@media only screen and (min-width: 950px) {
  .round-image {
      max-width: 30%;
      margin: 0;
  }

  .event-section p {
    display: inline-block;
    max-width: 100%;
    margin: 5px 0;
    padding: 0;
  }

  .section-content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .section-content-left {
    width: 65%;
  }

  .section-content-left p {
    width: 80%;
  }

  .section-content-left .round-image {
    max-width: 70%;
    margin-bottom: 20px;
    padding: 5px;
  }

  .section-content-right {
    width: 35%;
  }

  .food-list {
  }
}