@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
.navbar--desktop {
  position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(0,0,0, .7);
  }
  
  .nav-logo--desktop {
    padding-left: 10px;
    color: white;
  }
  
  .navbar--desktop ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style-type: none;
    margin: 0;
  }
  
  .navbar--desktop ul li {
    /* padding: 15px 10px; */
  }
  
  .navbar--desktop ul li a{
    font-size: 1.2rem;
    padding: 15px 15px;
    color: white;
    display: block;
    height: 100%;
    text-decoration: none;
  }

  .menu-link--desktop--logo a {
    padding: 2.5px;
  }

  .menu-link--desktop--logo img {
    max-height: 80px;
  }

  .menu-link--desktop:hover {
    text-decoration: underline;
  }
.icon--mobile {
    position: fixed;
    z-index: 1000;
    font-size: 30px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    animation: pulse 8s infinite ease;
}

.fa-times {
    color: white;
}

.fa-bars {
    color: #777;
}

.navbar--mobile {
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    color: white;
    transform: translate(0, -100%);
    transition: transform 0.2s ease;
}

.show-nav--mobile {
    transform: translate(0);
}


/* STYLES */ 

.navbar--mobile ul {
    width: 100%;
    height: 100%;
    list-style: none;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.navbar--mobile ul li {
    width: 100%;
    text-align: center;
}

.navbar--mobile ul li a {
    display: block;
    color: white;
    border-bottom: 2px solid black;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 1em;
}

.navbar--mobile ul li a:hover, .navbar--mobile ul li a:focus {
    text-decoration: underline;
}
footer {
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  
  /*** LOGO ***/
  
  .footer--logo {
    font-size: 1em;
    text-align: left;
    padding: 25px 0;
    margin: 5px 15px;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: right;
    align-content: center;
    flex-direction: column;
  }

  .footer--logo img {
    max-width: 200px;
  }
  
  /* Tablet */
  @media(min-width: 767px) {
    .footer--logo {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .footer--logo--col1 {
      text-align: left;
    }
  }
  
  .footer--logo--col1 {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 0 10px 5px 0;
  }
  
  .footer--logo--links{
    color: white;
  }
  
  .footer--logo--col1 p {
    font-size: 1.1em;
    padding: 4px;
  }
  
  .footer--logo--col1 p a{
    color: white;
    text-decoration: none;
  }
  
  .footer--logo--col1 p a:hover{
    text-decoration: underline;
  }

  /*** CONTACT ***/

  .footer--contact h3 {
    font-size: 1.5rem;
  }
  
  /*** COLUMNS ***/
  
  .footer--columns {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
  }
  
  /* Tablet */
  @media(min-width: 767px) {
    .footer--columns {
      grid-template-columns: repeat(2, 50%);
      text-align: left;
    }
  }
  
  /* Desktop */
  @media(min-width: 1025px) {
    .footer--columns {
      grid-template-columns: repeat(3, 33%);
    }
  }
  
  .footer--column {
    margin: 20px 50px;
  }
  
  .footer--column h3 {
    text-decoration: underline;
  }
  
  .footer--column ul {
    list-style-type: none;
  }
  
  .footer--column--link {
    font-size: .85em;
    text-decoration: none;
    color: white;
  }
  
  .footer--column--link:hover {
    text-decoration: underline;
  }
  
  
  /*** LEGAL ***/
  
  .footer--legal {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: left;
    font-size: .8em;
    font-style: italic;
    margin: 5px 15px;
    padding: 30px 0;
    border-top: 1px solid white;
  }
/***** CONTACT INFO ****/
.contact-info {
  margin-bottom: 20px;
}

.contact-info-list {
  list-style: none;
  text-align: center;
}

.contact-info-list li {
  padding: 2px;
}

/***** EVENT INFO ****/
.event-section {
  text-align: center;
  margin: 50px auto;
}

.round-image {
    border-radius: 2.5%;
    max-width: 80%;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: transform 0.5s, opacity 0.5s;
}

.round-image:hover {
    transform: scale(1.02);
    opacity: 1;
}

.section-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-content-left {
  box-sizing: border-box;
  width: 100%;
}

.section-conten-right {
  box-sizing: border-box;
  width: 100%;
}

.food-list {
  list-style: none;  
}

.event-rules {
  margin: 0 auto;
  text-align: center;
}

.event-rules div img {
  max-width: 100%;
}

@media only screen and (min-width: 950px) {
  .round-image {
      max-width: 30%;
      margin: 0;
  }

  .event-section p {
    display: inline-block;
    max-width: 100%;
    margin: 5px 0;
    padding: 0;
  }

  .section-content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .section-content-left {
    width: 65%;
  }

  .section-content-left p {
    width: 80%;
  }

  .section-content-left .round-image {
    max-width: 70%;
    margin-bottom: 20px;
    padding: 5px;
  }

  .section-content-right {
    width: 35%;
  }

  .food-list {
  }
}
.hero-text-box {
    min-height: 100vh;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.hero-text-box div {
    position: absolute;
    top: 275px;
    left: 5%;
}

.hero-text-box div h1 {
    font-size: 2rem;
}

.hero-text-box div p {
    padding: 7.5px;
    font-size: 1.5rem;
}

.hero-text-box a {
    scroll-behavior: smooth;
}

@media only screen and (min-width: 921px) {
    .hero-text-box div h1 {
        font-size: 3.5rem;
    }

    .hero-text-box div p {
        font-size: 3rem;
    }
}
.full-width-gallery-wrapper {
    width: 100%;
    display: flex;
    zoom: 1;
    flex-direction: column;
}

.full-width-gallery-photo {
    width: 100%;
    overflow: hidden;
    background-color: #000;
    margin: 0;
}

.full-width-gallery-photo img {
    opacity: 0.7;
    width: 100%;
    height: auto;
    transform: scale(1.15);
    transition: transform 0.5s, opacity 0.5s;
}

.full-width-gallery-photo img:hover {
    transform: scale(1.05);
    opacity: 1;
}

@media only screen and (min-width: 550px) {
    .full-width-gallery-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
    }
    .full-width-gallery-photo {
        width: 50%;
    }
}

@media only screen and (min-width: 921px) {
    .full-width-gallery-photo {
        width: 25%;
    }

    .full-width-gallery-wrapper {
    }
}
.sponsors-link,
.sponsors-link:hover,
.sponsors-link:visited {
  width: 100%;
  height: 100%;
  display: contents;
  color: black;
}

.sponsors-wrapper {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.sponsors-large-heading,
.sponsors-medium-heading,
.sponsors-small-heading {
  font-family: 'Roboto', sans-serif;
}

.sponsors-large-heading {
  font-size: 3rem;
}

.sponsors-medium-heading {
  font-size: 2.5rem;
}

.sponsors-small-heading {
  font-size: 2rem;
}

.sponsors-list {
  text-align: center;
}

.sponsors-item {
  font-size: 1.5rem;
  list-style: none;
  padding: 10px 0;
  margin: 20px 0;
}

.sponsors-item img {
  max-width: 300px;
  max-height: 350px;
}

.l-text {
  font-size: 2.5rem;
  text-align: center;
}


@media only screen and (min-width: 950px) {
  .sponsors-wrapper section {
    margin: 20px auto !important;
    padding: 0 !important;
  }

  .sponsors-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin: 0;
  }

  .sponsors-item {
    width: 25%;
    box-sizing: border-box;
    /* margin: 25 10px; */
    margin: 0;
    padding: 0;
  }

  .sponsors-item img {
    box-sizing: border-box;
    max-width: 100%;
    max-height: 200px;
    padding: 0px 20px;
  }
  
  .sponsors-large-heading {
    font-size: 3rem;
    margin: 0;
    padding: 0;
  }

  .sponsors-medium-heading {
    font-size: 2rem;
  }

  .sponsors-small-heading {
    font-size: 1.5rem;
  }

  .l-text {
    font-size: 1.5rem;
  }

  .specialty-container {
    display: flex; 
  }
  .specialty-container .sponsors-item {
    width: 40%;
  }

}
.content--section {
  margin: 30px 0;
  padding: 25px;
  color: white;
}

.vendorIcon {
color: green;
font-size: 2rem;
padding: 5px;
}

.sponsor-levels-title {
font-size: 2.5rem;
text-align: center;
padding: 15px;
}

.sponsor-levels-title::after {
display: block;
height: 2px;
background-color: rgb(72, 185, 69);
content: " ";
width: 100px;
margin: 30px auto 0px;
}

.sponsor-cards-wrapper {
display: flex;
flex-wrap: wrap;
}

.sponsor-card {
width: 100%;
text-align: center;
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
margin: 10px 0;
}

.sponsor-card:hover {
box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.sponsor-title {
font-size: 2.5rem;
font-weight: bold;
padding: 10px;
margin: 0;
border-bottom: 1px solid rgba(0,0,0,.2);
}

.sponsor-price {
font-size: 1.75rem;
padding: 7.5px;
color: green;
border-bottom: 1px solid rgba(0,0,0,.2);
margin: 0;
}

.sponsor-list {
text-align: left;
list-style: none;
padding: 10px;
}

.sponsor-list-item {
padding: 2.5px; 
margin: 2px 0;
font-size: 1.1rem;
}

.fa-check {
margin-right: 5px;
}

@media only screen and (min-width: 950px) {

.sponsor-cards-wrapper {
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0;
  margin: 0
}

.sponsor-card {
  box-sizing: border-box;
  width: 45%;
  margin: 15px;
}
}
.pilot-card-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.pilot-card {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: 10px;
}

@media only screen and (min-width: 500px) {
    .pilot-card {
        width: 50%;
    }
}

@media only screen and (min-width: 921px) {
    .pilot-card {
        width: 25%;
    }
}

.pilot-card--image {
    max-height: 200px;
    border-radius: 10%;
    margin: 10px 0;
}

.pilot-card--text {
    display: flex;
    flex-direction: column;
}

.pilot-card--balloon-name {
    text-decoration: underline;
    text-align: center;
    font-size: 1.25rem;
}

.pilot-card--pilot-name,
.pilot-card--sponsor,
.pilot-card--location {
    text-align: center;
    font-size: 1rem;
}

.italic {
    font-style: italic;
}
.content--section {
    margin: 30px 0;
    padding: 25px;
    color: white;
  }

.event-rules div img {
  max-width: 100%;
}
  
.top-section h2,
.top-section h3 {
  text-align: center;
}
.top-section h2 {
  font-size: 1.75rem;
}

.top-section h3 {
  font-size: 1.25rem;
  margin: 2px auto;
}

.schedule-wrapper {
  padding: 5px;
  width: 100%;
}

.day-wrapper {
  margin: 0 auto;
  padding: 10px 0;
  text-align: left;
}

.event-day {
  font-size: 2rem;
  font-weight: bolder;
  text-decoration: underline;
}

.event-info-wrapper {
  list-style: none;
}

.event-info {
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1.1rem;
  margin: 15px 0;
  padding: 0 5px;
}

.event-info:nth-child(odd) {
  background-color: rgba(119, 119, 119, 0.15);
}

.event-time {
  font-weight: 900;
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
}

.event-info-text {
  text-align: right;
  margin: 0;
  padding: 0;
}

.schedule-disclaimer {
  width: 80%;
  margin: 0 auto;
  padding: 25px;
  font-style: italic;
}

.event-rules {
  margin: 0 auto;
  text-align: center;
}

@media only screen and (min-width: 950px) {
  .event-time {
    width: 25%;
    text-align: left;
    margin: 0;
    padding: 0;
  }
  .event-info-text {
    text-align: left;
  }
}
.content--section {
    margin: 30px 0;
    padding: 25px;
    color: white;
  }

.vendorInfo {
  text-align: center;
  font-size: 1.25rem;
  padding: 5px;
}

.vendorButtons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vendorButtons div {
  margin-top: 5px;
}
.pilot-registration-wrapper {
    font-size: 1.1rem;
}

.pilot-registration-wrapper form section{
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 5px auto;
}

.pilot-registration-wrapper form input,
.pilot-registration-wrapper form select,
.pilot-registration-wrapper form textarea
 {
    margin: 5px;
    margin-bottom: 15px;
    padding: 5px;
}

.pilot-registration-wrapper form button {
    font-size: 1.5rem;
    font-weight: bold;
}

.pilot-registration-wrapper form button {
    margin-top: 10px;
    padding: 15px;
}

.font--bold {
    font-weight: bold;
}

.hotel-info p {
    text-align: center;
    margin: 2px;
    padding: 0;
}

.sending-spinner {
    display: inline-block;
    margin-left: 10px;
}

.warning-red {
    color: red;
}

.select-placeholder {
    color: #999;
    font-style: italic;
}
.maintenance__wrapper {
    min-height: 50vh;
    margin: 150px auto;
    width: 80%;
}

.maintenance__header {
    text-align: center;
}

.maintenance__image {
    display: block;
    margin: 0 auto;
}

.maintenance__text {
    text-align: center;
}

@media only screen and (max-width: 921px) {
    .maintenance__wrapper {
        width: 100%;
    }
}
.not-found__wrapper {
    min-height: 50vh;
    margin: 150px auto;
    width: 80%;
}

.not-found__header {
    text-align: center;
}

.not-found__image {
    display: block;
    margin: 0 auto;
}

.not-found__text {
    text-align: center;
}

@media only screen and (max-width: 921px) {
    .not-found__wrapper {
        box-sizing: border-box;
        width: 100%;
    }
    .not-found__image,
    .not-found__text,
    .not-found__header {
        max-width: 80%;
        margin: 0 auto;
    }
    .not-found__text {
        text-align: center;
    }
}
.btn:link,
.btn:visited,
input[type=submit] {
    font-size: 1.1rem;
    display: inline-block;
    padding: 10px 30px;
    font-weight: 300;
    text-decoration: none;
    border-radius: 100px;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
}

.btn-full:link,
.btn-full:visited,
input[type=submit] {
    background-color: #48B945;
    border: 1px solid #48B945;
    color: #fff;
    margin-right: 15px;
}

.btn-ghost:link,
.btn-ghost:visited {
    color: #48B945;
    border: 1px solid #48B945;
}

.btn:hover,
.btn:active,
input[type=submit]:hover,
input[type=submit]:active {
    background-color: #3ea03c;
}

.btn-full:hover,
.btn-full:active,
input[type=submit]:hover,
input[type=submit]:active {
    border: 1px solid #3ea03c;
}

.btn-ghost:hover,
.btn-ghost:active {
    border: 1px solid #3ea03c;
    color: #fff;
}

.learn-more-btn {
    background-color: #3ea03c;
    border: 1px solid #3ea03c;
    color: #fff;
    font-size: 1.1rem;
    display: inline-block;
    padding: 10px 30px;
    font-weight: 300;
    text-decoration: none;
    border-radius: 100px;
    transition: background-color 0.2s, border 0.2s, color 0.2s;

}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

html, body, #root, .root {
    height: 100%;
}

.root {
    display: flex;
    flex-direction: column;
}

header {
    z-index: 1000;
}

.content {
    flex: 1 0 auto;
    -webkit-filter: brightness(120%);
            filter: brightness(120%);
}

.top-section {
    padding-top: 75px;
}

section {
    padding: 15px 0;
    width: 80%;
    margin: 5px auto;
}

section h1 {
    text-align: center;
    padding: 10px;
    font-size: 2.5rem;
    word-spacing: 2px;
}

section h1:after {
    display: block;
    height: 2px;
    background-color: #48B945;
    content: " ";
    width: 100px;
    margin: 15px auto;
}

section h2 {
    text-align: center;
    font-size: 1.75rem;
    margin-bottom: 15px;
}

section p {
    padding: 5px;
    margin: 5px 0;
}

.content .content--nav {
    margin-top: 5px;
}

.content--nav {
    text-decoration: none;
}

.content--nav li {
    list-style: none;
    padding: 2.5px;
    text-align: center;
}


/**** FOOTER ****/

footer {
    flex-shrink: 0;
}
.content--section {
  margin: 30px 0;
  padding: 25px;
  color: white;
}

.special-shape-balloon-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.special-shape-balloon-wrapper {
  width: 100%;
  text-align: center;
  margin: 30px auto;
  text-decoration: underline;
}

.special-shape-balloon-wrapper h2 {
  font-size: 1.9em;
}

.special-shape-balloon {
  max-width: 300px;
  transition: transform .2s; /* Animation */
  padding: 10px;
  box-shadow: 8px 8px 10px #aaa;
  border: 5px double transparent;
  border-image: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%);
  border-image-slice: 1;
}

.special-shape-balloon:hover {
  transform: scale(105%);
}

.special-shape--sponsor {
  text-align: center;
  font-size: 1.4rem;
  padding: 15px;
}

.italic {
  font-style: italic;
}

@media only screen and (min-width: 950px) {
  .special-shape-balloon-wrapper {
    width: 50%;
  }
}
